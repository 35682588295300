<template>
  <div id="ef01">
    <div class="efwrap">
      <div class="headwrap">
        {{title}}
        <div class="close"
             @click="close"><img src="@/assets/images/common/cicon01.png" alt=""></div>
      </div>
      <div class="contentwrap"
           :class="{cword:filetype ==1}">
        <iframe :src="url"
                frameborder='0'
                v-if="filetype ==3"></iframe>
        <iframe :src="baseUrl2 + url"
                frameborder='0'
                v-else-if="filetype ==1"></iframe>
        <img :src="url"
             alt
             v-else-if="filetype ==2" />
        <!-- <video-player :url="url"
                      ref="video"
                      v-else-if="filetype ==4 && url"></video-player> -->
        <img v-else-if="filetype ==5"
             v-for="(item,index) in url"
             :key="index"
             style="width:200px;height:200px;position: initial;object-fit: fill;margin: 20px 20px;"
             :src="baseUrl+item"
             alt />
      </div>
    </div>
  </div>
</template> 
<script>
// import videoPlayer from "@/components/commons/video";
import { baseUrl } from "@/js/util.js";
export default {
  name: "ef01",
  props: {
    title: String,
    url: Array,
    filetype: { // 1 word 2 img 3 pdf 4 video
      default: 0
    }
  },
  data () {
    return {
      baseUrl2: 'https://view.officeapps.live.com/op/view.aspx?src=',
      baseUrl: baseUrl
    };
  },
  // components: { videoPlayer },
  mounted () {
    console.log(this.url)
  },
  methods: {
    close () {
      this.$emit('closeef01');
    }
  },
  destroyed () {

  }
};
</script> 
<style  lang = "scss" >
#ef01 {
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.4);
  z-index: 999;
  .efwrap {
    position: absolute;
    top: 10%;
    left: 15%;
    bottom: 145px;
    right: 15%;
    z-index: 999;
    border: 1px solid #666;
    border-radius: 3px;
    /* box-shadow: 0 0 15px #00b4ff; */
    .headwrap {
      height: 54px;
      background: #fff;
      position: absolute;
      top: 0;
      width: 100%;
      color: #fff;
      box-sizing: border-box;
      padding: 0 40px;
      line-height: 54px;
      font-size: 16px;
      border-bottom: 1px solid #777;
      z-index: 99999;
      .close {
        position: absolute;
        width: 36px;
        height: 36px;
        right: 40px;
        top: 50%;
        transform: translate(0, -50%);
        cursor: pointer;
        /* background: url(@/assets/images/common/cicon01.png) no-repeat; */
        background-size: 100% 100%;
      }
    }
    .contentwrap {
      position: absolute;
      width: 100%;
      background: #fff;
      &.cword {
        top: 4px;
        bottom: -47px;
      }
      top: 54px;
      bottom: 0;
      z-index: 9999;
    }
    iframe,
    img {
      position: absolute;
      height: 100%;
      width: 100%;
      box-sizing: border-box;
    }
    img {
      object-fit: contain;
    }
  }
}
</style>