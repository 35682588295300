import axios from 'axios';

import {baseUrl} from '@/js/util.js'

// 新增修改模型数据
export const AddModelManagement=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ModelManagement/AddModelManagement`,
        data:datas
    })
}

// 获取模型类型列表
export const GeteModelSubordinateModuleCategoryList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ModelManagement/GeteModelSubordinateModuleCategoryList`,
        params:datas
    })
}

// 获取模型类型列表
export const GeteModelManagementList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ModelManagement/GeteModelManagementList`,
        params:datas
    })
}

// 删除模型数据
export const DeleteModelManagement=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/ModelManagement/DeleteModelManagement`,
        params:datas
    })
}

// 新增模型拆分构件信息
export const SynchronousModel=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/ModelManagement/SynchronousModel`,
        data:datas
    })
}
// 删除资料文件数据
export const delete_documentManagement=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/delete_documentManagement`,
        params:datas
    })
}
// 获取资料文件分页数据
export const get_documentManagementPageList=(datas)=>{
    return axios({
        method:'get',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/get_documentManagementPageList`,
        params:datas
    })
}
// 添加/修改资料文件管理
export const InsertOrUpdate_documentManagement=(datas)=>{
    return axios({
        method:'post',
        url:`${baseUrl}/Intelligence_Building_API/SystemManagement/InsertOrUpdate_documentManagement`,
        data:datas
    })
}
